<template>
  <div class="productRegistration">
    <div class="registration-item">
      <div class="wen">产品名称</div>
      <div class="input">
        <a-input v-model="name" />
      </div>
    </div>
    <div class="registration-item">
      <div class="wen">产品型号</div>
      <div class="input">
        <a-input v-model="model" />
      </div>
    </div>
    <div class="registration-item">
      <div class="wen">序列号</div>
      <div class="input">
        <a-input v-model="serialNumber" />
      </div>
    </div>
    <div class="registration-item">
      <div class="wen">购买日期</div>
      <div class="input">
        <a-date-picker @change="onChangeDate" style="width: 100%;" />
      </div>
    </div>

    <div class="registration-item">
      <div class="wen">购买渠道</div>
      <div class="input">
        <a-input v-model="channel" />
      </div>
    </div>
    <div class="registration-item">
      <div class="wen">订单号</div>
      <div class="input">
        <a-input v-model="invoice" />
      </div>
    </div>
    <div class="registration-item">
      <div class="wen">店名</div>
      <div class="input">
        <a-input v-model="tradeName" />
      </div>
    </div>

    <div class="registration-item">
      <div class="wen">订单编号</div>
      <div class="input">
        <a-input v-model="order" />
      </div>
    </div>
    <div class="registration-item">
      <div class="wen">品牌</div>
      <div class="input">
        <a-input v-model="brand" />
      </div>
    </div>

    <div class="btn" @click="handleOk">注册产品</div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      model: '',
      serialNumber: '',
      date: '',
      channel: '',
      invoice: '',
      name: '',
      order: '',
      brand: '',
      tradeName: '',
    };
  },
  computed: {
    ...mapState('user', ['info']),
  },

  methods: {
    handleOk(e) {
      if (_.isEmpty(this.info)) {
        this.$message.warning('请先去登录');
        return;
      }
      if (this.info.shenhe === 0 || this.info.shenhe === 2) {
        this.$message.warning('后台正在审核您的账号，请等待');
        return;
      }
      if (_.isEmpty(this.name)) {
        this.$message.warning('请输入产品名称');
        return;
      }
      if (_.isEmpty(this.model)) {
        this.$message.warning('请输入产品型号');
        return;
      }
      if (_.isEmpty(this.serialNumber)) {
        this.$message.warning('请输入序列号');
        return;
      }
      if (_.isEmpty(this.serialNumber)) {
        this.$message.warning('请输入序列号');
        return;
      }
      if (_.isEmpty('' + this.date)) {
        this.$message.warning('请选择购买日期');
        return;
      }
      if (_.isEmpty(this.channel)) {
        this.$message.warning('请输入购买渠道');
        return;
      }
      if (_.isEmpty(this.invoice)) {
        this.$message.warning('请输入订单号');
        return;
      }
      if (_.isEmpty(this.tradeName)) {
        this.$message.warning('请输入店名');
        return;
      }
      if (_.isEmpty(this.order)) {
        this.$message.warning('请输入订单编号');
        return;
      }
      if (_.isEmpty(this.brand)) {
        this.$message.warning('请输入品牌');
        return;
      }
      let data = {
        token: this.info.token,
        name: this.name,
        promodel: this.model,
        xuhao: this.serialNumber,
        riqi: '' + this.date,
        qudao: this.channel,
        orders: this.invoice,
        dianming: this.tradeName,
        bianhao: this.order,
        pinpai: this.brand,
      };
      return this.$request(Apis.productRegistration.PRODUCT, { data: data }).then(data => {
        this.$message.success('注册产品成功');
      });
    },
    handleCancel(e) {
      this.$emit('change', false);
    },
    onChangeDate(date, dateString) {
      this.date = this.$moment(dateString).valueOf();
    },
  },
};
</script>

<style lang="less" scoped>
.productRegistration {
  width: 60%;
  margin: 50px auto;
  .registration-item {
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    margin-bottom: 30px;
    .wen {
      font-size: 16px;
      color: #fff;
      text-align: right;
      width: 20%;
    }
    .input {
      width: 40%;
    }
  }

  .btn {
    width: 40%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 10px;
    background: #ffcc00;
    margin: auto;
    color: #fff;
    margin-top: 50px;
  }
  .forget-title {
    text-align: center;
    color: #ffcc00;
    font-size: 24px;
    margin: 30px 0;
  }
}
</style>
