var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "productRegistration" }, [
    _c("div", { staticClass: "registration-item" }, [
      _c("div", { staticClass: "wen" }, [_vm._v("产品名称")]),
      _c(
        "div",
        { staticClass: "input" },
        [
          _c("a-input", {
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "registration-item" }, [
      _c("div", { staticClass: "wen" }, [_vm._v("产品型号")]),
      _c(
        "div",
        { staticClass: "input" },
        [
          _c("a-input", {
            model: {
              value: _vm.model,
              callback: function($$v) {
                _vm.model = $$v
              },
              expression: "model"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "registration-item" }, [
      _c("div", { staticClass: "wen" }, [_vm._v("序列号")]),
      _c(
        "div",
        { staticClass: "input" },
        [
          _c("a-input", {
            model: {
              value: _vm.serialNumber,
              callback: function($$v) {
                _vm.serialNumber = $$v
              },
              expression: "serialNumber"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "registration-item" }, [
      _c("div", { staticClass: "wen" }, [_vm._v("购买日期")]),
      _c(
        "div",
        { staticClass: "input" },
        [
          _c("a-date-picker", {
            staticStyle: { width: "100%" },
            on: { change: _vm.onChangeDate }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "registration-item" }, [
      _c("div", { staticClass: "wen" }, [_vm._v("购买渠道")]),
      _c(
        "div",
        { staticClass: "input" },
        [
          _c("a-input", {
            model: {
              value: _vm.channel,
              callback: function($$v) {
                _vm.channel = $$v
              },
              expression: "channel"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "registration-item" }, [
      _c("div", { staticClass: "wen" }, [_vm._v("订单号")]),
      _c(
        "div",
        { staticClass: "input" },
        [
          _c("a-input", {
            model: {
              value: _vm.invoice,
              callback: function($$v) {
                _vm.invoice = $$v
              },
              expression: "invoice"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "registration-item" }, [
      _c("div", { staticClass: "wen" }, [_vm._v("店名")]),
      _c(
        "div",
        { staticClass: "input" },
        [
          _c("a-input", {
            model: {
              value: _vm.tradeName,
              callback: function($$v) {
                _vm.tradeName = $$v
              },
              expression: "tradeName"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "registration-item" }, [
      _c("div", { staticClass: "wen" }, [_vm._v("订单编号")]),
      _c(
        "div",
        { staticClass: "input" },
        [
          _c("a-input", {
            model: {
              value: _vm.order,
              callback: function($$v) {
                _vm.order = $$v
              },
              expression: "order"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "registration-item" }, [
      _c("div", { staticClass: "wen" }, [_vm._v("品牌")]),
      _c(
        "div",
        { staticClass: "input" },
        [
          _c("a-input", {
            model: {
              value: _vm.brand,
              callback: function($$v) {
                _vm.brand = $$v
              },
              expression: "brand"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "btn", on: { click: _vm.handleOk } }, [
      _vm._v("注册产品")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }